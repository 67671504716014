'use client';

import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

import { LinkSlashIcon } from '@/components/icons/link-slash-icon';
import { useTenantName } from '@/hooks/utils/use-tenant-name';
import { setPageTitle } from '@/utils/set-page-title';

export default function PageNotFound() {
  const t = useTranslations('pageNotFound');
  const tenantName = useTenantName();

  const titleText = t('title');
  const pageTitle = t('metaTitle_name', { name: tenantName });
  const descText = t('desc');

  useEffect(() => {
    setPageTitle(pageTitle);
  }, [pageTitle]);

  return (
    <div
      data-testid="not-found-page-section"
      className="flex h-full w-full flex-col items-start justify-center p-4 lg:items-center"
    >
      <div>
        <div className="mb-6 flex flex-col items-start gap-8 lg:flex-row lg:items-center">
          <LinkSlashIcon className="mr-auto inline-block h-8 text-error lg:mr-2" />
          <h1
            data-testid="not-found-page-title"
            className="font-bold text-black font-heading text-2xl-bold md:text-3xl-bold"
          >
            {titleText}
          </h1>
        </div>
        <p data-testid="not-found-page-description" className="max-w-[600px]">
          {descText}
        </p>
      </div>
    </div>
  );
}
